import Top from './projects/top';
import Acc from './modules/acc';
import Gnav from './modules/gnav';
import InView from './modules/inview';
//import MenuWhite from './modules/menu_white';

function init() {
  Top.init();
  Acc.init();
  Gnav.init();
  InView.init();
  //MenuWhite.init();
}

export default {
  init,
};
