import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

function init() {

  const $el_hero = document.querySelector('.p-topHero');

  if ($el_hero) {
    heroSlider();
    thumbSlider();
    creatorSlider();

    // 一枚目の画像読み込み後にフェードインさせる
    // ※ swiper-lazyを使えばcssのアニメ設定のみで対処できるが、imgに対してだけしかできない
    firstImgLoadEnd();
  }
}

function heroSlider() {
  const $el = document.querySelector('.p-topHero__block');

  if (!$el) return;

  return new Swiper($el, {
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    speed: 1000,
    loop: true,
    // effect: 'fade',
    // fadeEffect: {
    //   crossFade: false,
    // },
    // lazy: true, // loading="lazy"が設定されていれば不要
    lazyPreloadPrevNext: 1, // 現在のスライドの前後1スライドを事前に読み込む
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });
}

function firstImgLoadEnd() {
  const firstSlideInner = document.querySelector('.p-topHero__item:first-child .__inner.--first');
  const firstSlideImg = firstSlideInner.querySelector('img');

  firstSlideImg.addEventListener('load', () => {
    firstSlideInner.classList.add('is-loaded');
  });

  // 画像がキャッシュされている場合に備える
  if (firstSlideImg.complete) {
    firstSlideImg.dispatchEvent(new Event('load'));
  }
}

function thumbSlider() {
  const $el = document.querySelector('.p-topThumb');

  if (!$el) return;

  return new Swiper($el, {
    slidesPerView: 1.33,
    spaceBetween: 10,
    //centeredSlides: true,
    // autoplay: {
    //   delay: 2000,
    //   disableOnInteraction: false,
    // },
    // speed: 1000,
    //loop: true,
    breakpoints: {
      640: {
        slidesPerView: 2.33,
        spaceBetween: 10,
      },
      840: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1240: {
        slidesPerView: 3.5,
        spaceBetween: 40,
      },
    },
  });
}

function creatorSlider() {
  const $el = document.querySelector('.p-topCreator__slider');

  if (!$el) return;

  return new Swiper($el, {
    slidesPerView: 1.33,
    spaceBetween: 10,
    //centeredSlides: true,
    // autoplay: {
    //   delay: 2000,
    //   disableOnInteraction: false,
    // },
    // speed: 1000,
    //loop: true,
    breakpoints: {
      640: {
        slidesPerView: 2.33,
        spaceBetween: 10,
      },
      840: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1240: {
        slidesPerView: 3.5,
        spaceBetween: 40,
      },
    },
  });
}

export default {
  init,
};
