function init() {
  // accordionのクリックアクション/初期化
  const $accordion = document.querySelector('.js-acc');
  if ($accordion) {
    setupAccordionItems();
  }
}

// accordionのクリックアクションを設定
function setupAccordionItems() {
  const items = document.querySelectorAll('.js-acc-item');

  const setItemHeight = (item, isActive) => {
    const title = item.querySelector('.js-acc-ttl');
    const content = item.querySelector('.js-acc-cont');

    if (isActive) {
      const contentHeight = content.offsetHeight;
      item.style.height = `${title.offsetHeight + contentHeight}px`;
    } else {
      item.style.height = `${title.offsetHeight}px`;
    }
  };

  // アイコンクリックでプラス・マイナスの切り替え
  const toggleIcon = (icon, isActive) => {
    if (isActive) {
      icon.classList.add('is-minus');
    } else {
      icon.classList.remove('is-minus');
    }
  };

  // OPENとCLOSEのテキスト切り替え
  const toggleButtonText = (textElement, isActive) => {
    if (isActive) {
      textElement.textContent = 'CLOSE';
    } else {
      textElement.textContent = 'OPEN';
    }
  };

  // 初期設定
  items.forEach(item => {
    const title = item.querySelector('.js-acc-ttl');
    const button = item.querySelector('.c-btnAcc');
    const textElement = button.querySelector('.__txt');
    const icon = button.querySelector('.__icon');

    setItemHeight(item, item.classList.contains('is-active'));

    title.addEventListener('click', () => {
      const isActive = item.classList.contains('is-active');
      if (isActive) {
        setItemHeight(item, false);
        item.classList.remove('is-active');
      } else {
        setItemHeight(item, true);
        item.classList.add('is-active');
      }
      // テキストとアイコンの切り替え
      toggleButtonText(textElement, !isActive);
      toggleIcon(icon, !isActive);
    });
  });

  // ウィンドウリサイズ時に高さを再計算
  window.addEventListener('resize', () => {
    items.forEach(item => {
      const isActive = item.classList.contains('is-active');
      setItemHeight(item, isActive);
    });
  });
}

export default {
  init,
};
