function init() {
  const headerMenuBtn = document.querySelector('.l-header__menuBtn');
  const targetElement = document.querySelector('.l-headerSpNav');
  const navLinks = document.querySelectorAll('.l-headerSpNav li a');
  const hasHero = document.querySelector('.has-hero');

  if (!headerMenuBtn || !targetElement) return;  // メニュー要素が存在しなければ終了

  // メニュー操作機能を有効にする
  toggleMenuActive(headerMenuBtn, targetElement);
  closeMenuOnLinkClick(navLinks, headerMenuBtn, targetElement);

  // .has-hero が存在する場合、スクロール管理機能を有効にする
  if (hasHero) {
    toggleWhiteClassOnScroll(headerMenuBtn, hasHero);
  }
}

function toggleMenuActive(menuBtn, targetElement) {
  menuBtn.addEventListener('click', () => {
    menuBtn.classList.toggle('is-active');
    targetElement.classList.toggle('is-active');

    if (menuBtn.classList.contains('is-active')) {
      menuBtn.classList.remove('is-white');  // メニューオープン時に is-white を外す
    } else {
      // メニューがクローズされた時に、hasHero の範囲を確認
      checkAndToggleWhiteClass(menuBtn);
    }
  });
}

function closeMenuOnLinkClick(navLinks, menuBtn, targetElement) {
  const hasHero = document.querySelector('.has-hero');

  navLinks.forEach(link => {
    link.addEventListener('click', () => {
      menuBtn.classList.remove('is-active');
      targetElement.classList.remove('is-active');

      // メニューが閉じるときに、hasHeroの範囲内であれば is-white を付与
      checkAndToggleWhiteClass(menuBtn);
    });
  });
}

function toggleWhiteClassOnScroll(headerMenuBtn, hasHero) {
  const heroBottom = hasHero.offsetTop + hasHero.offsetHeight;

  // 初期状態で .is-white を付与
  headerMenuBtn.classList.add('is-white');

  window.addEventListener('scroll', function() {
    const windowTop = window.scrollY;

    // メニューが開いていない場合のみスクロールで is-white を制御
    if (!headerMenuBtn.classList.contains('is-active')) {
      if (windowTop > heroBottom) {
        headerMenuBtn.classList.remove('is-white');  // スクロールが .has-hero 範囲外に達したら is-white を削除
      } else {
        headerMenuBtn.classList.add('is-white');  // 範囲内に戻ったら is-white を付与
      }
    }
  });
}

function checkAndToggleWhiteClass(menuBtn) {
  const hasHero = document.querySelector('.has-hero');

  if (hasHero) {
    const heroBottom = hasHero.offsetTop + hasHero.offsetHeight;
    const windowTop = window.scrollY;

    // メニューが閉じたときに、範囲内であれば is-white を付与
    if (windowTop <= heroBottom) {
      menuBtn.classList.add('is-white');  // 範囲内であれば is-white を付与
    } else {
      menuBtn.classList.remove('is-white');  // 範囲外であれば is-white を削除
    }
  }
}

export default {
  init,
};
